import {
	Box,
	chakra,
	Container,
	Image,
	Link,
	Stack,
	Text,
	useColorModeValue,
	VisuallyHidden,
  } from '@chakra-ui/react';
  import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa';
  import { ReactNode } from 'react';
  
  const Logo = (props: any) => {
	return (
		<Box>
			<Image 
				src="/Images/lotipoAzul.svg"
				alt="logotipo"
				boxSize="9rem"
			/>
		</Box>
	)
  };
  
  const SocialButton = ({
	children,
	label,
	href,
  }: {
	children: ReactNode;
	label: string;
	href: string;
  }) => {
	return (
	  <chakra.button
		bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
		rounded={'full'}
		w={8}
		h={8}
		cursor={'pointer'}
		as={'a'}
		href={href}
		target="blank"
		display={'inline-flex'}
		alignItems={'center'}
		justifyContent={'center'}
		transition={'background 0.3s ease'}
		_hover={{
		  bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
		}}>
		<VisuallyHidden>{label}</VisuallyHidden>
		{children}
	  </chakra.button>
	);
  };
  
  export function Footer() {
	return (
	  <Box
		bg={useColorModeValue('gray.50', 'gray.900')}
		color={useColorModeValue('gray.700', 'gray.200')}>
		<Container
		  as={Stack}
		  maxW={'6xl'}
		  py={4}
		  direction={{ base: 'column', md: 'row' }}
		  spacing={4}
		  justify={{ base: 'center', md: 'space-between' }}
		  align={{ base: 'center', md: 'center' }}>
		  <Logo />
		  <Text opacity="0.6">© 2022 Contabilidade Fácil. All rights reserved</Text>
		  <Text opacity="0.6">Developed by <Link target="blank" color="yellow.300"  href="https://portfolio-guusta032.vercel.app/">Gustavo Ramos</Link></Text>
		  <Stack direction={'row'} spacing={6}>
			<SocialButton label={'Whatsapp'} href={'https://wa.me/5511988069223'}>
			  <FaWhatsapp />
			</SocialButton>
			<SocialButton label={'Facebook'} href={'https://m.facebook.com/Contabilidade-F%C3%A1cil-108526955391958'}>
			  <FaFacebook />
			</SocialButton>
			<SocialButton label={'Instagram'} href={'https://instragram.com/cont_facil10'} >
			  <FaInstagram />
			</SocialButton>
		  </Stack>
		</Container>
	  </Box>
	);
  }