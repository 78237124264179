import {
	Box,
	Flex,
	Text,
	IconButton,
	Button,
	Stack,
	Collapse,
	Icon,
	Link,
	Popover,
	PopoverTrigger,
	PopoverContent,
	useColorModeValue,
	useBreakpointValue,
	useDisclosure,
	Image,
	useColorMode,
	Divider,
	Center,
  } from '@chakra-ui/react';
   
  import {
	HamburgerIcon,
	CloseIcon,
	ChevronDownIcon,
	ChevronRightIcon,
	MoonIcon, SunIcon
  } from '@chakra-ui/icons';
import { useState } from 'react';
import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
	
const xl = defineStyle({
    border: "10rem solid",
    borderRadius: 'lg',
})

export const dividerTheme = defineStyleConfig({
    sizes: { xl },
})

export function Header() {
	const { isOpen, onToggle } = useDisclosure();
	const [ navBarOpen, isNavBarOpen ] = useState(true)
	const { colorMode, toggleColorMode } = useColorMode();



	return (
	  <Box position="fixed" top="0" marginTop={ navBarOpen ? 0 : "-100%" } width="100%" zIndex="100">
		
		<Flex
		  bg={useColorModeValue( 'gray.800', '#181f7f' )}
		  color={useColorModeValue( 'white', 'gray.900')}
		  maxH={'6.2rem'}
		  paddingTop="1.2rem"
		  paddingBottom="1.2rem"
		  px={{ base: 4}}
		  borderBottom={1}
		  borderStyle={'solid'}
		  borderColor={useColorModeValue('gray.200', 'gray.900')}
		  align={'center'}
		>
			<Flex
			flex={{ base: 1, md: 'auto' }}
			ml={{ base: -2 }}
			display={{ base: 'flex', md: 'none' }}>
			<IconButton
				onClick={onToggle}
				icon={
				isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
				}
				variant={'ghost'}
				aria-label={'Toggle Navigation'}
			/>
			</Flex>

			<Flex flex={{ base: 1 }} justify={{ base: 'right', md: 'space-between' }}>
			<Image src="/Images/lotipoBranc.svg" alt="logo" maxW="7rem"/>

			<Flex display={{ base: 'none', md: 'flex' }}>
				<DesktopNav />
			</Flex>

			<Box width={{ base: '0', md: '7rem' }}></Box>
			</Flex>
				
			<Center h="6.2rem" opacity="1">
				<Divider orientation="vertical" opacity="1" borderWidth="0.17rem" marginTop="-3rem" borderColor="yellow.400" backgroundColor="yellow.400" size={xl} />
			</Center>

			<Button onClick={toggleColorMode} colorScheme="black">
				{colorMode === 'light' ? <MoonIcon /> : <SunIcon color="#fff" />}
			</Button>
		</Flex>
  
		<Collapse in={isOpen} animateOpacity>
		  <MobileNav toggleNav={onToggle} />
		</Collapse>
	  </Box>
	);
  }
  
  const DesktopNav = () => {
	const linkColor = useColorModeValue('gray.200', 'white');
	const linkHoverColor = useColorModeValue('yellow.400', 'gray.800');
	const popoverContentBgColor = useColorModeValue('gray.800', 'white');
  
	return (
	  <Stack direction={'row'} spacing={4} alignItems="center">
		{NAV_ITEMS.map((navItem) => (
		  <Box key={navItem.label}>
			<Popover trigger={'hover'} placement={'bottom-start'}>
			  <PopoverTrigger>
				<Link
				  p={2}
				  href={navItem.href ?? '#'}
				  fontSize={'sm'}
				  fontWeight={500}
				  fontFamily="sans-serif"
				  color={linkColor}
				  _hover={{
					textDecoration: 'none',
					color: linkHoverColor,
				  }}>
				  {navItem.label}
				</Link>
			  </PopoverTrigger>
  
			  {navItem.children && (
				<PopoverContent
				  border={0}
				  boxShadow={'xl'}
				  bg={popoverContentBgColor}
				  p={4}
				  rounded={'xl'}
				  minW={'sm'}>
				  <Stack>
					{navItem.children.map((child) => (
					  <DesktopSubNav key={child.label} {...child} />
					))}
				  </Stack>
				</PopoverContent>
			  )}
			</Popover>
		  </Box>
		))}
	  </Stack>
	);
  };
  
  const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {

	return (
	  <Link
		href={href}
		role={'group'}
		display={'block'}
		p={2}
		rounded={'md'}
		_hover={{ bg: useColorModeValue('gray.900', 'yellow.50') }}>
		<Stack direction={'row'} align={'center'}>
		  <Box>
			<Text
			  transition={'all .3s ease'}
			  _groupHover={{ color: 'yellow.400' }}
			  fontWeight={500}>
			  {label}
			</Text>
			<Text fontSize={'smaller'} pt={1} fontFamily="sans-serif">{subLabel}</Text>
		  </Box>
		  <Flex
			transition={'all .3s ease'}
			transform={'translateX(-10px)'}
			opacity={0}
			_groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
			justify={'flex-end'}
			align={'center'}
			flex={1}>
			<Icon color={'yellow.400'} w={5} h={5} as={ChevronRightIcon} />
			
		  </Flex>
		</Stack>
	  </Link>
	);
  };
  
  const MobileNav = ({toggleNav}:NavItem) => {
	return (
	  <Stack
		bg={useColorModeValue('gray.800', 'white')}
		p={4}
		display={{ md: 'none' }}>
		{NAV_ITEMS.map((navItem) => (
		  <MobileNavItem toggleNav={toggleNav} key={navItem.label} {...navItem} />
		))}
	  </Stack>
	);
  };
  
  const MobileNavItem = ({ label, children, href, toggleNav }: NavItem) => {
	const { isOpen, onToggle } = useDisclosure();
  
	return (
	  <Stack spacing={4} onClick={children ? onToggle : toggleNav}>
		<Flex
		  py={2}
		  as={Link}
		  href={href ?? '#'}
		  justify={'space-between'}
		  align={'center'}
		  _hover={{
			textDecoration: 'none',
		  }}>
		  <Text
			fontWeight={600}
			fontFamily="sans-serif"
			color={useColorModeValue( 'gray.200', 'gray.600' )}
			>
			{label}
		  </Text>
		  {children && (
			<Icon
			 color="yellow.400"
			  as={ChevronDownIcon}
			  transition={'all .25s ease-in-out'}
			  transform={isOpen ? 'rotate(180deg)' : ''}
			  w={6}
			  h={6}
			/>
		  )}
		</Flex>
  
		<Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
		  <Stack
			pl={4}
			borderLeft={1}
			borderStyle={'solid'}
			borderColor={useColorModeValue('yellow.400','gray.200')}
			align={'start'}>
			{children &&
			  children.map((child) => (
				<Link onClick={toggleNav} color="gray.300" fontFamily="sans-serif" key={child.label} py={2} href={child.href}>
				  {child.label}
				</Link>
			  ))}
		  </Stack>
		</Collapse>
	  </Stack>
	);
  };
  

  interface NavItem {
	label?: string;
	subLabel?: string;
	children?: Array<NavItem>;
	href?: string;
	toggleNav?: ()=>void
  }
  
  const NAV_ITEMS: Array<NavItem> = [
	{
	  label: 'Home',
	  href: '/'
	},
	{
	  label: 'Sobre',
	  href: '/#about',	  
	},
	{
		label: 'Dúvidas',
		href: '/#contact',
		children: [
			{
			  label: 'Entre em contato',
			  subLabel: 'Converse com um dos nossos contadores',
			  href: '/#contact',
			},
		  ],
	}
  ];