import {
	Container,
	Flex,
	Box,
	Heading,
	Text,
	IconButton,
	Button,
	VStack,
	HStack,
	Wrap,
	WrapItem,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputLeftElement,
	Textarea,
	Link,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription
  } from '@chakra-ui/react';
  import {
	MdPhone,
	MdEmail,
	MdLocationOn,
	MdFacebook,
	MdOutlineEmail,
	MdOutlinePhone,
	MdSmartphone,
  } from 'react-icons/md';
  import { BsGithub, BsDiscord, BsPerson, BsWhatsapp, BsInstagram } from 'react-icons/bs';
import { useState } from 'react';
  
  export function Contact() {
	// https://formspree.io/f/xeqdbwwe
	const [ resultFormPost, setResultFormPost ] = useState('')
	const [errorMessage, setErrorMessage] = useState({
		title: 'Não identificamos o erro',
		message: 'tente novamente mais tarde'
	})
	async function sendContactFunction(event:any){
		event.preventDefault()
		
		const dataForm = {
			Subject: 'CONTATO SITE!!!',
			Nome: event.target[0].value,
			Email: event.target[1].value,
			Telefone: event.target[2].value,
			Mensagem: event.target[3].value
		} 

		console.log(dataForm)

		// await fetch("https://api.github.com/users/gustavo032", {
		// 		method: "GET"})

			await fetch("https://formspree.io/f/xeqdbwwe", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(dataForm),
				})
			.then(async(response) => {
				let responseData = await response.json()
				setResultFormPost('success')

				if(responseData.error == 'Validation errors'){
					setResultFormPost("validationError")
				 }else if(responseData.error){
				 	setResultFormPost('erro')
				 }
				console.log(responseData.error)
				console.log(responseData)
				
				setErrorMessage({
					title: responseData.error,
					message: responseData.errors[0].message
				})
			}).then(()=>{
				
			})
			
			// console.log(resultFormPost)
		return("success")
	}
	

	return (
	  <Container id="contact" bg="#9DC4FB" maxW="full" mt={0} centerContent overflow="hidden">
		<Flex>
		  <Box
			bg="#02054B"
			color="white"
			borderRadius="lg"
			m={{ sm: 4, md: 16, lg: 10 }}
			p={{ sm: 5, md: 5, lg: 16 }}>
			<Box p={4}>
			  <Wrap justifyContent="center" spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
				<WrapItem>
				  <Box>
					<Heading>Contato</Heading>
					<Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.500">
					  Preencha o formulário para entrar em contato
					</Text>
					<Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
					  <VStack pl={0} spacing={3} alignItems="flex-start">
						<Button
						as={Link}
						href="tel:5511042052525"
						target="blank"						  
						  size="md"
						  height="48px"
						  width="100%"
						  variant="ghost"
						  color="#DCE2FF"
						  _hover={{ border: '2px solid #1C6FEB' }}
						  leftIcon={<MdPhone color="#1970F1" size="20px" />}>
						  +55 11 4205-2525
						</Button>
						<Button
					 	  as={Link}
					 	  href="tel:5511988069223"
						  target="blank"						  
						  size="md"
						  height="48px"
						  width="100%"
						  variant="ghost"
						  color="#DCE2FF"
						  _hover={{ border: '2px solid #1C6FEB' }}
						  leftIcon={<MdSmartphone color="#1970F1" size="20px" />}
						>
						  +55 11 98806-9223
						</Button>
						<Button
							as={Link}
							href="mailto:comercial@contfacil.com"
						  size="md"
						  height="48px"
						  width="100%"
						  variant="ghost"
						  color="#DCE2FF"
						  _hover={{ border: '2px solid #1C6FEB' }}
						  leftIcon={<MdEmail color="#1970F1" size="20px" />}>
						  comercial@contfacil.com
						</Button>
						<Button
						  as={Link}
						  href="https://goo.gl/maps/jHggeNCrLjceuoBE9"
						  target="blank"
						  size="md"
						  height="48px"
						  width="100%"
						  variant="ghost"
						  color="#DCE2FF"
						  _hover={{ border: '2px solid #1C6FEB' }}
						  leftIcon={<MdLocationOn color="#1970F1" size="20px" />}>
						  Itapevi, São Paulo - Brasil
						</Button>
					  </VStack>
					</Box>
					<HStack
						placeContent="center"
						w="100%"
					  mt={{ lg: 10, md: 10 }}
					  spacing={5}
					  px={5}
					  alignItems="flex-start">
					  <IconButton
						aria-label="facebook"
						as={Link}
						target="blank"
						href="https://m.facebook.com/Contabilidade-F%C3%A1cil-108526955391958"
						variant="ghost"
						size="lg"
						isRound={true}
						_hover={{ bg: '#0D74FF' }}
						icon={<MdFacebook size="28px" />}
					  />
					  <IconButton
						aria-label="Whatsapp"
						variant="ghost"
						size="lg"
						isRound={true}
						_hover={{ bg: '#0D74FF' }}
						as={Link}
						target="blank"
						href="https://wa.me/5511988069223"
						icon={<BsWhatsapp size="28px" />}
					  />
					  <IconButton
						aria-label="Instagram"
						variant="ghost"
						size="lg"
						isRound={true}
						as={Link}
						target="blank"
						href="https://instragram.com/cont_facil10"
						_hover={{ bg: '#0D74FF' }}
						icon={<BsInstagram size="28px" />}
					  />
					</HStack>
				  </Box>
				</WrapItem>
				<WrapItem>
				  <Box bg="white" borderRadius="lg">
					<Box m={8} color="#0B0E3F">
							{ resultFormPost == '' &&
								<form method="post" onSubmit={(event)=>{sendContactFunction(event)}}>
									<VStack spacing={5}>
										<FormControl id="name">
											<FormLabel>Seu Nome</FormLabel>
											
											<InputGroup  borderColor="#E0E1E7">
												<InputLeftElement
												pointerEvents="none"
												children={<BsPerson color="gray.800" />}
												/>
												<Input required={true} type="text" name="Nome" size="md" />
											</InputGroup>
										</FormControl>
										<FormControl id="mail">
											<FormLabel>E-mail</FormLabel>

											<InputGroup borderColor="#E0E1E7">
												<InputLeftElement
												pointerEvents="none"
												children={<MdOutlineEmail color="gray.800" />}
												/>
												<Input required={true} type="email" name="E-mail" size="md" />
											</InputGroup>
										</FormControl>
										<FormControl id="tel">
											<FormLabel>Tel./Celular</FormLabel>

											<InputGroup borderColor="#E0E1E7">
												<InputLeftElement
												pointerEvents="none"
												children={<MdOutlinePhone color="gray.800" />}
												/>
												<Input required={true} type="number" name="Telefone" size="md" />
											</InputGroup>
										</FormControl>
										<FormControl id="mensagem">
											<FormLabel>Mensagem</FormLabel>
											
											<Textarea
												required={true}
												name="Mensagem"
												borderColor="gray.300"
												_hover={{
												borderRadius: 'gray.300',
												}}
												placeholder="mensagem"
											/>
										</FormControl>
										<FormControl id="send" float="right">
											<Button
												type="submit"
												variant="solid"
												bg="#0D74FF"
												color="white"
												_hover={{}}>
												Enviar Mensagem
											</Button>
										</FormControl>
									</VStack>

								</form>
							}
							{ resultFormPost == "validationError" &&
								<Alert
									status='error'
									variant='subtle'
									flexDirection='column'
									alignItems='center'
									justifyContent='center'
									textAlign='center'
									height='200px'
								>
								<AlertIcon boxSize='40px' mr={0} />
								<AlertTitle mt={4} mb={1} fontSize='lg'>
								  ERRO! Verifique Todos os Campos e tente novamente.
								</AlertTitle>
								<AlertDescription maxWidth='sm'>
									&#40;{errorMessage.title}: {errorMessage.message}&#41;
								</AlertDescription>
							  </Alert>
							}
							{ resultFormPost == "erro" &&
								 <Alert
								 status='error'
								 variant='subtle'
								 flexDirection='column'
								 alignItems='center'
								 justifyContent='center'
								 textAlign='center'
								 height='200px'
								>
									<AlertIcon boxSize='40px' mr={0} />
									<AlertTitle mt={4} mb={1} fontSize='lg'>
									ERRO! Verifique Todos os Campos e tente novamente.
									</AlertTitle>
									<AlertDescription maxWidth='sm'>
										&#40;{errorMessage.title}: {errorMessage.message}&#41;
									</AlertDescription>
								</Alert>
							}
							{  resultFormPost == 'success' &&
								<Alert
								status='success'
								variant='subtle'
								flexDirection='column'
								alignItems='center'
								justifyContent='center'
								textAlign='center'
								height='200px'
							   >
								   <AlertIcon boxSize='40px' mr={0} />
								   <AlertTitle mt={4} mb={1} fontSize='lg'>
								   	ENVIADO COM SUCESSO!
								   </AlertTitle>
								   <AlertDescription maxWidth='sm'>
									   Aguarde o retorno, entraremos em contato. Obrigado!
								   </AlertDescription>
							   </Alert>
							}
					</Box>
				  </Box>
				</WrapItem>
			  </Wrap>
			</Box>
		  </Box>
		</Flex>
	  </Container>
	);
  }