import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex, Icon, Image, Link as chakraLink, Link, Stack, Text } from '@chakra-ui/react';
import { FiInstagram, FiFacebook, FiTwitter } from 'react-icons/fi'
import { About } from './components/About';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import {Hero} from './components/Hero';

function App() {
  return (
	<>
		<header>
			<Header />
		</header>	
		<Hero />

		<main>
			<About />
			<Contact />
		</main>

	
		<footer>
			<Footer />
		</footer>
	</>
  );
}

export default App;
